import PropTypes from 'prop-types';
import {
  Flex,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import Key from './Key.js';
import React from 'react';

const Combination = ({command, context = 'help'}) => {
  const commandSplit = command.split('+');
  return (
    <>
      {commandSplit.map(key => <Key key={key} value={key} context={context}/>)}
    </>);
};

Combination.propTypes = {
  command: PropTypes.string,
  context: PropTypes.string,
};

const Sequence = ({sequenceList, context = 'help'}) => {
  const {colorMode} = useColorMode();
  return (
    <>
      {sequenceList?.map((combination, i) => {
        return (<React.Fragment key={i}>
          {i >= 1 && <Text mx="1" fontSize={'.85rem'} color={`${colorMode}.grey.200`}>then</Text>}
          <Combination command={combination} context={context}/>
        </React.Fragment>);
      })}
    </>
  );
};

Sequence.propTypes = {
  sequenceList: PropTypes.arrayOf(PropTypes.string),
  context: PropTypes.string,
};

const HotKeysCommand = ({command, context = 'help', ...props}) => {
  const sequence = command && command.split(' ');
  return (
    <Flex align="center" {...props}>
      <Sequence sequenceList={sequence} context={context}/>
    </Flex>
  );
};

HotKeysCommand.propTypes = {
  command: PropTypes.string,
  context: PropTypes.string,
};

export default HotKeysCommand;
