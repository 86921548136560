import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {connectBridge} from '../features/desktop/DesktopSlice';
import {isElectron} from '../utils/helpers';

const useBridge = () => {
  const dispatch = useDispatch();
  const bridgeStatus = useSelector(state => state.desktop.status);
  useEffect(() => {
    if (!isElectron) return;
    if (bridgeStatus === 'init') {
      dispatch(connectBridge());
    }
  }, [bridgeStatus]);
};

export default useBridge;
