import {
  useColorMode,
} from '@chakra-ui/react';

const useDarkMode = () => {
  const {colorMode, toggleColorMode} = useColorMode();
  if (colorMode === 'light') toggleColorMode();
};

export default useDarkMode;
