import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isElectron} from '../../utils/helpers';
import {
  useTheme,
  useColorMode,
  Flex,
  Text,
  Button,
} from '@chakra-ui/react';
import {IconClose, IconLogo} from '../../components/Icons';
import {
  setIgnoreUpdate,
  selectIsNewVersionAvailable,
  versionDownloaded,
} from './DesktopSlice';

const UpdateVersion = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {colorMode} = useColorMode();
  const [updating, setUpdating] = useState(false);
  const updateAvailable = useSelector(selectIsNewVersionAvailable);
  const ignoreUpdate = useSelector(state => state.desktop.ignoreUpdate);
  const showUpdate = updateAvailable && !ignoreUpdate;

  const handleUpdateDownloaded = (event, data) => {
    const {payload} = data;
    dispatch(versionDownloaded(payload));
  };
  const updateApp = async() => {
    setUpdating(true);
    await window.electron.message.invoke('updateApp', {type: 'INSTALL'});
  };
  const ignore = async() => {
    dispatch(setIgnoreUpdate(true));
  };
  useEffect(() => {
    if (isElectron && typeof window !== 'undefined') {
      window.electron.message.on('updateDownloaded', handleUpdateDownloaded);
    }
    return () => {
      if (isElectron && typeof window !== 'undefined') {
        window.electron.message.removeUpdateListeners();
      }
    };
  }, []);
  return (showUpdate &&
    <Flex
      id="app_update"
      direction="column"
      position="absolute"
      zIndex="1000"
      width="auto"
      height="auto"
      bottom="1rem"
      right="1rem"
      background={`${colorMode}.grey.600`}
      borderRadius=".5rem"
      pt="4"
      pb="4"
    >
      <Flex>
        <Flex
          align="start"
          px="1.5rem"
        >
          <IconLogo />
        </Flex>
        <Flex
          direction="column"
        >
          <Text fontWeight="bold">
            New Version Available
          </Text>
          <Text>
            Restart app to install
          </Text>

        </Flex>
        <Flex
          cursor="pointer"
          px="1.5rem"
          sx={{
            '&:hover path': {
              fill: `${colorMode}.grey.100`,
            },
          }}
        >
          <IconClose
            width="1rem"
            height="1rem"
            color={theme.colors[colorMode].grey[400]}
            handleClick={ignore}
            style={{
              cursor: 'pointer',
            }}
          />
        </Flex>
      </Flex>
      <Button
        mt="4"
        mx="5"
        onClick={updateApp}
        disabled={updating}
        isLoading={updating}
      >
        Restart
      </Button>
    </Flex>
  );
};

export default UpdateVersion;
