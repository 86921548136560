import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isElectron} from '../../utils/helpers';
import {buildErrorMessage} from '../../../helpers';
import {
  useColorMode,
  Flex,
  Text,
  Button,
  Box,
  useToast,
  Progress,
} from '@chakra-ui/react';
import {
  loadUsersWorkspaces,
} from '../../app/WorkspacesSlice';
import {
  selectAppReady,
} from '../../app/store';

const Sync = ({
  context = 'app',
}) => {
  const appReady = useSelector(selectAppReady);
  const toast = useToast();
  const {colorMode} = useColorMode();
  const dispatch = useDispatch();
  const [componentBody, setComponentBody] = useState(null);
  const [progress, setProgress] = useState(null);
  const [progressType, setProgressType] = useState('Push');
  const respondToCli = (event, input) => {
    event.sender.send('cliPull', {payload: input});
  };
  const pushHandler = (event, data) => {
    const {error} = data;
    if (error) {
      error.forEach(err => {
        dispatch(toast({
          title: err.title || err.name,
          description: err.json ? buildErrorMessage(err.json) : undefined,
          status: 'error',
          duration: 9000,
          isClosable: true,
        }));
      });
    }
  };
  const handler = (event, data) => {
    // attach a new handler
    window.electron.message.once('cliPull', handler);
    if (data.type === 'overwrite') {
      setComponentBody(
        <Flex
          direction="column"
          justify="center"
          align="center"
        >
          <Text mb="3" fontSize="lg" color={`${colorMode}.white.300`}>Remote Sync Conflict</Text>
          <Box display="inherit" my="2">
            <code>{data.payload}</code>
            <Text ml={2}>has unpushed changes.</Text>
          </Box>
          <Text my="2">Would you like to overwrite your local changes?</Text>
          <Flex>
            <Button
              colorScheme="red"
              m="2"
              onClick={() => {
                respondToCli(event, 'y');
                setComponentBody(null);
              }}
            >Yes</Button>
            <Button
              colorScheme="blue"
              m="2"
              onClick={() => {
                respondToCli(event, 'n');
                setComponentBody(null);
              }}
            >No</Button>
          </Flex>
        </Flex>,
      );
    } else if (data.type === 'updated') {
      const {dirsAdded, filesAdded, filesUpdated} = data.payload;

      if (dirsAdded.length || filesAdded.length || filesUpdated.length) {
        dispatch(loadUsersWorkspaces());
      }
      if (dirsAdded.length > 0 || filesAdded.length > 0 || filesUpdated.length > 0) {
        if (dirsAdded.length > 0) {
          toast({
            title: `${dirsAdded.length} folder${dirsAdded.length > 1 ? 's' : ''} added`,
            status: 'info',
            duration: 9000,
            isClosable: true,
          });
        }
        if (filesAdded.length > 0) {
          toast({
            title: `${filesAdded.length} file${filesAdded.length > 1 ? 's' : ''} added`,
            status: 'info',
            duration: 9000,
            isClosable: true,
          });
        }
        if (filesUpdated.length > 0) {
          toast({
            title: `${filesUpdated.length} file${filesUpdated.length > 1 ? 's' : ''} updated`,
            status: 'info',
            duration: 9000,
            isClosable: true,
          });
        }
        toast({
          title: 'Files Updated from remote',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        if (data.manual || context === 'setup') {
          toast({
            title: context === 'setup' ? 'No workspaces found' : 'All up to date',
            status: context === 'setup' ? 'info' : 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      }
    }
  };
  const cliHandler = async(event, data) => {
    const {type, current, total} = data;
    if (type === 'PUSH_PROGRESS') {
      if (progressType !== 'Push') setProgressType('Push');
      setProgress(Math.floor(100 * current / total));
    } else if (type === 'PUSH_START') {
      setProgress(0);
    } else if (type === 'PUSH_END') {
      setProgress(100);
      setTimeout(() => setProgress(null), 2000);
    } else if (type === 'PULL_PROGRESS') {
      if (progressType !== 'Pull') setProgressType('Pull');
      setProgress(Math.floor(100 * current / total));
    } else if (type === 'PULL_START') {
      setProgress(0);
    } else if (type === 'PULL_END') {
      setProgress(100);
      setTimeout(() => setProgress(null), 2000);
    }
  };
  useEffect(() => {
    if (isElectron && appReady) {
      window.electron.message.once('cliPull', handler);
      window.electron.message.on('cliPush', pushHandler);
      context === 'setup' && window.electron.message.on('cli', cliHandler);
    }
    return () => {
      if (isElectron && appReady) {
        window.electron.message.removeCliPullListener();
        window.electron.message.removeCliPushListener();
        context === 'setup' && window.electron.message.removeCliListener();
      }
    };
  }, [isElectron, appReady]);
  return (isElectron && (componentBody || progress !== null) &&
  <>
    {componentBody &&
    <Flex
      id="sync_container"
      position="absolute"
      zIndex="1000"
      width="auto"
      maxWidth="60%"
      height="auto"
      transform="translateX(-50%)"
      bottom="1rem"
      left="50%"
      align="center"
      justify="center"
      background={`${colorMode}.grey.900`}
      // opacity=".9"
      p="4"
      borderRadius="5"
    >
      {componentBody}
    </Flex>}
    {progress !== null &&
      <Flex p="1" w="15rem" maxW="80%" direction="row" position="absolute" right="2.5rem" bottom="2.5rem" zIndex="100" align="center">
        <Progress borderRadius=".35rem" hasStripe={true} colorScheme="facebook" w="calc(100% - 2.5rem)" h="1.5rem" size="lg" value={progress} />
        <Text ml="3" fontSize=".9rem" w="2.5rem">{`${progress}%`}</Text>
      </Flex>
    }
  </>
  );
};

export default Sync;
