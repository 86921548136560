import NextRouter, {useRouter} from 'next/router';
import {useEffect} from 'react';
import {selectAllWorkspaces, selectSelectedWorkspaceName, setSelectedWorkspace, selectAllWorkspaceIDsStringified} from '../app/WorkspacesSlice';
import * as Mousetrap from 'mousetrap';
import {useDispatch, useSelector} from 'react-redux';
import {useToast} from '@chakra-ui/react';
import {logout} from '../features/user/UserSlice';
import {isElectron} from '../utils/helpers';
import {syncFiles, selectFilePropById} from '../features/files/FilesSlice';
import {openLair, selectLairIds, selectLairView, selectLairViewName, runLair} from '../features/lairs/LairsSlice';
import {selectFocusedId} from '../features/editor/EditorSlice';
import {setHotkeysHelp} from '../features/hotkeys/HotKeysSlice';
import commands from '../features/hotkeys/commands';
import {resetTerminalProcessId} from '../features/processes/ProcessesSlice';
import {FEATURES} from '../features/flags';

const useAppHotkeys = ({
  view,
}) => {
  const showHotkeys = useSelector(state => state.hotkeys.help);
  const workspaceName = useSelector(selectSelectedWorkspaceName);
  const toast = useToast();
  const dispatch = useDispatch();
  const syncStatus = useSelector(state => state.files.syncStatus);
  const syncing = ['fetching', 'pulling', 'pushing'].includes(syncStatus);
  const workspaceIDsStringified = useSelector(selectAllWorkspaceIDsStringified);
  const workspaces = useSelector(selectAllWorkspaces);
  const router = useRouter();
  const lairId = useSelector(selectLairView);
  const lairName = useSelector(selectLairViewName);
  const focusedId = useSelector(selectFocusedId);
  const lairDeployed = !!useSelector(state => selectFilePropById(state, lairId, 'last_deployed'));
  const lairs = useSelector(selectLairIds);
  const numWorkspaces = workspaces.length;
  const numLairs = lairs.length;

  const {
    openDocs,
    contactSupport,
    logout: logoutCommand,
    workspaceHome,
    sync,
    openWorkspace,
    workspaceSettings,
    workspaceMembers,
    workspaceGroups,
    workspaceApplicationKeys,
    openLair: openLairCommand,
    lairDevelop,
    lairReadme,
    lairDeploy,
    lairAlerts,
    lairLogs,
    lairPublish,
    lairEndpoints,
    runLair: runLairCommand,
    environmentDevelop,
    environmentProd,
    saveFile,
    closeTab,
    restartTerminal,
    workspaceSetup,
  } = commands;

  const openWorkspaceAtIndex = async(index) => {
    if (workspaces.length >= index + 1) {
      const workspace = workspaces[index];
      await dispatch(setSelectedWorkspace(workspace.id));
      router.push(`/workspaces/${workspace.name}`);
    }
  };

  const HOTKEYS_LIST = [
    {
      label: 'Open Docs',
      command: openDocs,
      checkIsInvalid: () => {
      },
      action: () => {
        window.open('https://wsxdocs.wayscript.com/');
      },
      electron: true,
      web: true,
      categoryID: 'general',
    },
    {
      label: 'Contact Support',
      command: contactSupport,
      checkIsInvalid: () => {
      },
      action: () => {
        location.href = 'mailto:nihar@wayscript.com';
      },
      electron: true,
      web: true,
      categoryID: 'general',
    },
    {
      label: 'Logout',
      command: logoutCommand,
      checkIsInvalid: () => {
      },
      action: async() => {
        await dispatch(logout());
      },
      electron: true,
      web: true,
      categoryID: 'general',
    },
    {
      label: 'Go to home',
      command: workspaceHome,
      checkIsInvalid: () => {
        if (!workspaceName) return 'Must have workspace open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}`);
      },
      electron: true,
      web: true,
      categoryID: 'workspace',
    },
    {
      label: 'Sync Changes  ',
      command: sync,
      checkIsInvalid: () => {
        if (!isElectron) {
          return 'cant sync changes in web app';
        } else {
          if (syncing) return 'wait until sync completes';
        }
      },
      action: () => {
        dispatch(syncFiles());
      },
      electron: true,
      web: false,
      categoryID: 'workspace',
    },
    {
      label: 'Create/Join workspace',
      command: workspaceSetup,
      checkIsInvalid: () => {
        return view === 'setup';
      },
      action: () => {
        dispatch(setSelectedWorkspace(null));
      },
      electron: true,
      web: false,
      categoryID: 'workspace',
    }];
  for (let i = 0; i < numWorkspaces; i++) {
    HOTKEYS_LIST.push({
      id: 'open_workspace',
      label: `Open Workspace ${i + 1}`,
      command: openWorkspace(i + 1),
      groupCommand: i === 0 ? openWorkspace(`1-${numWorkspaces}`) : undefined,
      groupLabel: i === 0 ? 'Open Workspace' : undefined,
      action: async() => {
        openWorkspaceAtIndex(i);
      },
      checkIsInvalid: () => {
      },
      electron: true,
      web: true,
      categoryID: 'workspace',
    });
  }
  HOTKEYS_LIST.push({
    label: 'Go to settings',
    command: workspaceSettings,
    checkIsInvalid: () => {
      if (!workspaceName) return 'Must have workspace open';
    },
    action: () => {
      NextRouter.push(`/workspaces/${workspaceName}/settings`);
    },
    electron: true,
    web: true,
    categoryID: 'workspace',
  },
  {
    label: 'Go to workspace members',
    command: workspaceMembers,
    checkIsInvalid: () => {
      if (!workspaceName) return 'Must have workspace open';
    },
    action: () => {
      NextRouter.push(`/workspaces/${workspaceName}/settings/members`);
    },
    electron: true,
    web: true,
    categoryID: 'workspace',
  });
  if (FEATURES.userGroups) {
    HOTKEYS_LIST.push({
      label: 'Go to workspace user groups',
      command: workspaceGroups,
      checkIsInvalid: () => {
        if (!workspaceName) return 'Must have workspace open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/settings/groups`);
      },
      electron: true,
      web: true,
      categoryID: 'workspace',
    });
  }
  if (FEATURES.applicationKeys) {
    HOTKEYS_LIST.push({
      label: 'Go to workspace application keys',
      command: workspaceApplicationKeys,
      checkIsInvalid: () => {
        if (!workspaceName) return 'Must have workspace open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/settings/api/`);
      },
      electron: true,
      web: true,
      categoryID: 'workspace',
    });
  }
  for (let i = 0; i < numLairs; i++) {
    HOTKEYS_LIST.push({
      id: 'open_lair',
      label: `Open Lair ${i + 1}`,
      command: openLairCommand(i + 1),
      groupCommand: i === 0 ? openLairCommand(`1-${numLairs}`) : undefined,
      groupLabel: i === 0 ? 'Open Lair' : undefined,
      checkIsInvalid: () => {
        if (!workspaceName) return 'Must have workspace open';
      },
      action: async() => {
        dispatch(openLair(lairs[i]));
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    });
  }
  HOTKEYS_LIST.push(
    {
      label: 'Go to develop',
      command: lairDevelop,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/develop`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Go to Readme',
      command: lairReadme,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/readme`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Go to Deploy',
      command: lairDeploy,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/deploy`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Go to Alerts',
      command: lairAlerts,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/alerts`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Go to Log',
      command: lairLogs,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/logs`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Go to Publish',
      command: lairPublish,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/publish`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Go to endpoints',
      command: lairEndpoints,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/endpoints`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Run Lair',
      command: runLairCommand,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        dispatch(runLair());
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Restart Terminal',
      command: restartTerminal,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        dispatch(resetTerminalProcessId());
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'View Prod Environment',
      command: environmentProd,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
        if (!lairDeployed) return 'Lair must be deployed';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/develop/prod`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Go to Dev Environment',
      command: environmentDevelop,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (!lairName) return 'Must have lair open';
      },
      action: () => {
        NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/develop`);
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Save File Changes',
      command: saveFile,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (view !== 'develop' || !focusedId) return 'File must be opened';
      },
      action: () => {
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
    {
      label: 'Close Editor Tab',
      command: closeTab,
      checkIsInvalid: () => {
        // return 'cant navigate to home from this location';
        if (view !== 'develop' || !focusedId) return 'File must be opened';
      },
      action: () => {
      },
      electron: true,
      web: true,
      categoryID: 'lair',
    },
  );

  useEffect(() => {
    Mousetrap.bind('shift+/', () => {
      dispatch(setHotkeysHelp(!showHotkeys));
    });
  }, [showHotkeys]);

  useEffect(() => {
    HOTKEYS_LIST.forEach(hk => {
      Mousetrap.bind(hk.command, (e) => {
        if (e.preventDefault) e.preventDefault();
        const isInvalid = hk.checkIsInvalid();
        if (isInvalid) {
          toast({
            title: isInvalid,
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
        } else {
          hk.action();
        }
      });
    });
    return () => {
      HOTKEYS_LIST.forEach(hk => {
        Mousetrap.unbind(hk.command);
      });
    };
  }, [workspaceName, workspaceIDsStringified, lairId, view, focusedId, syncing, lairDeployed, numLairs, numWorkspaces]);

  const processHotkeysMenuData = () => {
    const groupIDs = ['open_workspace', 'open_lair'];

    const categories = [
      {
        id: 'general',
        label: 'General',
      },
      {
        id: 'workspace',
        label: 'Workspace',
      },
      {
        id: 'lair',
        label: 'Lair',
      },
    ];

    const data = categories.map((category) => {
      const categoryHKs = HOTKEYS_LIST.filter(hk => hk.categoryID === category.id);

      const options = categoryHKs.reduce((acc, hk, index) => {
        if (groupIDs.includes(hk.id)) {
          const last = acc[acc.length - 1];
          if (acc.length === 0 || last.id !== hk.id) {
            acc.push({
              id: hk.id,
              command: hk.groupCommand,
              label: hk.groupLabel,
              isInvalid: !!hk.checkIsInvalid(),
            });
          }
        } else {
          acc.push({
            id: hk.id,
            command: hk.command,
            label: hk.label,
            isInvalid: !!hk.checkIsInvalid(),
          });
        }
        return acc;
      }, []);

      return {
        title: category.label,
        options,
      };
    });

    return data;
  };

  return processHotkeysMenuData();
};

export default useAppHotkeys;
