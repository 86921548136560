import {
  Flex,
  useColorMode,
} from '@chakra-ui/react';
const Key = ({value, context = 'help'}) => {
  const {colorMode} = useColorMode();
  // must account for OS dependent key differences
  let background;
  let color;
  let px;
  switch (context) {
  case 'menu':
  case 'dropdown':
    background = 'transparent';
    color = `${colorMode}.grey.200`;
    px = '1';
    break;
  case 'help':
  case 'tooltip':
  default:
    background = `${colorMode}.primary.500`;
    color = `${colorMode}.white.500`;
    px = '3';
  }
  return (
    <Flex
      borderRadius=".3rem" px={px} mx="1"
      color={color}
      background={background}
      fontWeight="300"
    >
      {value}
    </Flex>
  );
};

export default Key;
