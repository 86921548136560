import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Router from 'next/router';
import {getAccessToken, deleteAccessToken} from '../utils/authentication';
import {selectAppReady} from '../app/store';
import {
  fetchCurrentUser,
  setAccessToken,
} from '../features/user/UserSlice';
import {buildRequestUrl} from '../utils/helpers';

export default function useUser({
  accessToken,
  redirectTo = false,
} = {}) {
  const dispatch = useDispatch();
  const appReady = useSelector(selectAppReady);
  const userStatus = useSelector(state => state.user.status);
  const tokenStatus = useSelector(state => state.user.status_access_token);

  const getAuth = async() => {
    const token = accessToken || await getAccessToken();
    if (token) dispatch(setAccessToken({accessToken: token}));
    else if (redirectTo) Router.push(buildRequestUrl(redirectTo));
  };

  const switchUser = async(newToken) => {
    const storedToken = await getAccessToken();
    if (storedToken && newToken !== storedToken) {
      await deleteAccessToken();
      window.location.reload();
    }
  };
  useEffect(() => {
    if (accessToken) switchUser(accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (appReady && tokenStatus === 'init') getAuth();
  }, [tokenStatus, appReady, accessToken]);

  useEffect(() => {
    if (typeof window === 'undefined' || userStatus === 'logout') return;
    if (userStatus === 'init' && tokenStatus === 'idle') {
      dispatch(fetchCurrentUser());
    }
  }, [userStatus, tokenStatus]);

  const logout = async() => {
    Router.push(buildRequestUrl('/auth/logout'));
  };

  useEffect(() => {
    if (userStatus === 'logout') logout();
  }, [userStatus]);

  return accessToken || tokenStatus === 'idle';
}
