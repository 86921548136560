import {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import {getStoredItem, throttle} from '../utils/helpers';
import {selectAppReady} from '../app/store';

const retrieveItem = throttle((item, currentValue, setValue) => {
  return new Promise(resolve => {
    getStoredItem(item).then(value => {
      if (value && currentValue !== value) setValue(value);
      resolve();
    });
  });
}, 5000);

/** use cookies or electron-store to access stored item
 */
export default function useStoredItem({
  item,
  dependentValue,
} = {}) {
  const [itemState, setItemState] = useState('init');
  const interval = useRef(null);
  const appReady = useSelector(selectAppReady);

  useEffect(() => {
    if (!appReady) return;
    retrieveItem(item, itemState, setItemState);
    if (interval.current) clearInterval(interval.current);
    interval.current = setInterval(() => {
      retrieveItem(item, itemState, setItemState);
    }, 2000);
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [appReady, dependentValue]);

  return itemState;
}
