import {useEffect, useRef} from 'react';
import {isElectron} from '../utils/helpers';
import {useSelector, useDispatch} from 'react-redux';
import {
  loadUsersWorkspaces,
  selectAllWorkspaces,
  setSelectedWorkspace,
  selectSelectedWorkspacePropById,
  fetchWorkspaceData,
  fetchWorkspaceFeatureFlags,
} from '../app/WorkspacesSlice';
import {
  fetchFileChildren, selectFilePropById,
} from '../features/files/FilesSlice';
import {selectAppReady} from '../app/store';
import useStoredItem from './useStoredItem';

// hook that returns state.workspaces.directory
// and performs dispatches actions based on its current state
export default function useWorkspaces({
  directory,
  isLoggedIn,
  ifNoneSelected,
  ifSelected,
  ifStatusSelect,
  ifStatusInit,
  forcedSelection,
}) {
  const appReady = useSelector(selectAppReady);
  const workspacesFetched = useRef(false);
  const workspacesStatus = useSelector(state => state.workspaces.status);
  const workspaces = useSelector(selectAllWorkspaces);
  const selected = useSelector(state => state.workspaces.selected);
  const userId = useSelector(state => state.user.id);
  const dispatch = useDispatch();
  const storedSelection = useStoredItem({item: 'workspace', dependentValue: selected});
  const fileChildrenFetchedStatus = useSelector(state => selectFilePropById(state, selected, 'status_children_fetched'));

  const getForcedSelection = (forcedSelection) => {
    if (forcedSelection) {
      const {propName, value} = forcedSelection;
      const match = workspaces.filter(w => w[propName] === value)[0];
      if (match) return match.id;
    }
    return null;
  };
  const setSelection = forcedSelection ? getForcedSelection(forcedSelection) : storedSelection;

  useEffect(() => {
    if (!fileChildrenFetchedStatus && selected && isLoggedIn) {
      if (isElectron) dispatch(loadUsersWorkspaces());
      else dispatch(fetchFileChildren({id: selected, updateStatus: true}));
    }
  }, [selected, isLoggedIn, fileChildrenFetchedStatus]);

  useEffect(() => {
    if (!appReady) return;
    if (userId) {
      if (selected === null) {
        if (workspacesStatus === 'select') {
          if (ifStatusSelect) ifStatusSelect();
        } else if (workspaces.length && setSelection && setSelection !== 'init') {
          const selection = workspaces.filter(w => w.id === setSelection)[0];
          if (selection && workspacesStatus !== 'select') {
            dispatch(setSelectedWorkspace(setSelection));
          } else {
            if (ifNoneSelected) ifNoneSelected();
          }
        } else if (workspacesStatus === 'init') {
          if (ifStatusInit) ifStatusInit();
        } else if (workspacesStatus === 'idle' && isLoggedIn) {
          if (ifNoneSelected) ifNoneSelected();
          else if (!workspacesFetched.current) {
            workspacesFetched.current = true;
            if (isElectron && directory && directory !== 'init') {
              dispatch(loadUsersWorkspaces());
            }
          }
        }
      } else {
        if (ifSelected) {
          ifSelected();
        }
      }
    }
  }, [userId, selected, workspacesStatus, isLoggedIn, appReady, directory]);

  const workspaceDataLoaded = useSelector(state => selectSelectedWorkspacePropById(state, 'dataLoaded'));
  useEffect(() => {
    if (selected && workspaceDataLoaded === undefined) dispatch(fetchWorkspaceData(selected));
  }, [selected, workspaceDataLoaded]);
  const flagsFetched = useSelector(state => selectSelectedWorkspacePropById(state, 'status_flags') !== 'init');
  useEffect(() => {
    if (selected && !flagsFetched) dispatch(fetchWorkspaceFeatureFlags(selected));
  }, [selected, flagsFetched]);

  return workspaces ? JSON.stringify(workspaces) : null;
}
