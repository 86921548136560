import {useEffect} from 'react';
import {isElectron} from '../utils/helpers';
import {useSelector, useDispatch} from 'react-redux';
import {
  setHomeDirectory,
} from '../app/WorkspacesSlice';
import {selectAppReady} from '../app/store';

// hook that returns state.workspaces.directory
// and performs dispatches actions based on its current state
export default function useDirectory({
  ifInit = false,
} = {}) {
  const dispatch = useDispatch();
  const appReady = useSelector(selectAppReady);
  const directory = useSelector(state => state.workspaces.directory);
  const userId = useSelector(state => state.user.id);

  const setupDirectory = async(directory) => {
    if (!isElectron) return;
    if (directory === null) {
      await dispatch(setHomeDirectory());
    } else if (directory === 'init') {
      if (ifInit) ifInit();
    }
  };
  useEffect(() => {
    if (!isElectron || !appReady || !userId) return;
    setupDirectory(directory);
  }, [directory, appReady, userId]);

  return directory;
}
