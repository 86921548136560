import {PLATFORM} from '../../app/constants';
const option = PLATFORM === 'win'
  ? 'alt'
  : 'option';

const command = PLATFORM === 'win'
  ? 'control'
  : 'command';

const commands = {
  openDocs: 'shift+d',
  contactSupport: 'shift+s',
  logout: `${option}+shift+q`,
  workspaceHome: 'w h',
  sync: `${command}+shift+s`,
  openWorkspace: (index) => `w ${index}`,
  workspaceSettings: 'w s',
  workspaceMembers: 'w m',
  workspaceGroups: 'w g',
  workspaceApplicationKeys: 'w k',
  workspaceSetup: 'w n',
  openLair: (index) => `l ${index}`,
  lairDevelop: 'l e',
  lairReadme: 'l r',
  lairDeploy: 'l d',
  lairAlerts: 'l a',
  lairLogs: 'l o',
  lairPublish: 'l p',
  lairEndpoints: 'l u',
  runLair: 'l x',
  restartTerminal: 'l t',
  environmentDevelop: 'e d',
  environmentProd: 'e p',
  saveFile: 'meta+s',
  closeTab: 'meta+w',
};

export default commands;
