import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {
  Flex,
  useColorMode,
  Text,
  Fade,
  Box,
} from '@chakra-ui/react';
import HotKeysCommand from './HotKeysCommand';
import {setHotkeysHelp} from '../features/hotkeys/HotKeysSlice';
import Key from './Key.js';

const HotKeysHelp = ({open, hotkeys = [], showBg = true}) => {
  const {colorMode} = useColorMode();
  const hotKeysRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event) {
      if (hotKeysRef.current && !hotKeysRef.current.contains(event.target)) {
        dispatch(setHotkeysHelp(false));
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hotKeysRef]);

  return (
    <Fade in={open}>
      <Box
        w="100vw"
        h="100vh"
        position="absolute"
        top="0"
        backgroundColor={showBg ? 'rgba(44, 41, 48, .3)' : 'none'}
        zIndex={100}
      >
        <Flex
          position="fixed"
          right=".5rem"
          top=".5rem"
          background={`${colorMode}.bg.400`}
          width={open ? '25rem' : 0}
          maxWidth={open ? 'calc(100% - 1rem)' : 0}
          height={open && '98vh'}
          borderRadius="4"
          direction="column"
          zIndex="100"
          ref={hotKeysRef}
          overflow="auto"
          boxShados="rgb(0 0 0 / 20%) 0px 4px 24px"
        >
          <Flex p="4" direction="column">
            <Flex justify="space-between" align="center">
              <Text fontSize="lg" fontWeight="bold">Keyboard Shortcuts</Text>
              <Box h="1.5rem"><Key value="?" /></Box>
            </Flex>
            {hotkeys.map((section, i) => {
              return (
                <Flex key={i} direction="column">
                  <Text fontSize="md" fontWeight='bold' pt="4">{section.title}</Text>
                  {section.options.map((option, i) => {
                    return (
                      <Flex key={i} justify="space-between" py="3" opacity={option.isInvalid ? '0.3' : '1'}>
                        <Text color={`${colorMode}.grey.200`}>{option.label}</Text>
                        <HotKeysCommand command={option.command} />
                      </Flex>
                    );
                  })}
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Fade>
  );
};

HotKeysHelp.propTypes = {
  open: PropTypes.bool,
  hotkeys: PropTypes.arrayOf(PropTypes.objectj),
};

export default HotKeysHelp;
